<style>
  .el-collapse-item__header {
    font-weight: 600 !important;
  }
  .el-table .cell {
    word-break: unset;
  }
  .span-status {
    font-size: 1rem !important;
  }
</style>
<template>
  <div class="row">
    <div class="row col-md-12 g-6 mt-0" v-loading="loading">
      <div class="col-md-12 fv-row" v-if="productOptionDetails.value">
        <div class="card mb-5 mb-xl-10">
          <div class="card-header border-0 pt-6">
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2 float-left">
                    <span class="text-gray-900 fs-2 fw-bolder me-1">{{ productOptionDetails.value.option_name }}</span>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
                    <span class="d-flex align-items-center text-gray-400 me-5 mb-2">{{ productOptionDetails.value.option_description }}</span>
                  </div>
                </div>
                <div class="d-flex my-4">
                  <div class="fw-bold fs-6 text-gray-600">
                    <span v-if="productOptionDetails.value.status == 1" class="badge badge-light-success span-status">{{ constants.general.ACTIVE }}</span>
                    <span v-if="productOptionDetails.value.status == 0" class="badge badge-light-danger span-status">{{ constants.general.INACTIVE }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-wrap flex-stack">
                <div class="d-flex flex-wrap">
                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div class="d-flex justify-content-center">
                      <div class="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500">{{ productOptionDetails.value.sort_order }}</div>
                    </div>
                    <div class="fw-bold fs-6 text-gray-400 text-center">{{ constants.general.SORTORDER }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-5 mb-xl-10">
        <div class="card-header border-0 pt-6">
          <div class="col-md-12 fv-row">
            <h3>Attributes</h3>
            <div id="kt_accordion_1_body_1" class="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
            <div class="accordion-body">
              <Datatable
                :table-data="productOptionDetails.attributes"
                :table-header="attributeTableHeader"
                :enable-items-per-page-dropdown="true"
              >
                <template v-slot:cell-attribute_name="{ row: data }">
                  {{ data.attribute_name }}
                </template>
                <template v-slot:cell-sku_code="{ row: data }">
                  {{ data.sku_code }}
                </template>
                <template v-slot:cell-is_default="{ row: data }">
                  {{ data.is_default }}
                </template>
                <template v-slot:cell-sort_order="{ row: data }">
                  {{ data.sort_order }}
                </template>
                <template v-slot:cell-status="{ row: data }">
                  <span v-if="data.status == 1" class="badge badge-light-success">{{ constants.general.ACTIVE }}</span>
                  <span v-if="data.status == 0" class="badge badge-light-danger">{{ constants.general.INACTIVE }}</span>
                </template>
              </Datatable>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute, useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatText, formatPrice } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import useEventBus from '../../composable/useEventBus';
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "product-option-detail",
  components: {
    Datatable,
  },
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const route = useRoute();
    const router = useRouter();
    const productOptionDetails = reactive({attributes: [],});
    const loading = ref(false);
    const activePanel = ref('1');
    const constants = globalConstant;

    //Get click event of toolbar buttons
    onEvent('actionName', (actionName) => {
      if(actionName == "back"){
        router.push('/product-options');
      }
    })

    //Attribute table header data
    const attributeTableHeader = ref([
      {
        name: 'Attribute Name',
        key: "attribute_name",
        sortable: true,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: 'Attribute SKU',
        key: "sku_code",
        sortable: true,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: 'Is Default',
        key: "is_default",
        sortable: true,
        width: "w-100px",
        alignment: 'text-center'
      },
      {
        name: constants.general.SORTORDER,
        key: "sort_order",
        width: "w-100px",
        sortable: true,
        alignment: 'text-center'
      },
      {
        name: constants.general.STATUS,
        key: "status",
        width: "w-100px",
        sortable: false,
        alignment: 'text-center'
      }
    ]);

    //Get Product Option details
    const getProductOptionDetails = async() => {
      if(route.params.id){
        loading.value = true;
        await ApiService.post("product-master-option/get-product-option-detail/" + route.params.id)
        .then(({ data }) => {
          productOptionDetails.value = data.data;
          productOptionDetails.attributes = data.data.attributes;
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
          let message='';
          let response = error.response;
          let status = response.status;
          if (typeof (error.response) !== 'undefined') {
            message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
            message = error.message;
          } else {
            message = constants.general.NOTIFY_WRONG_MESSAGE;
          }
          if (status == 404 || status == 422) {
            router.push({ name: 'product-options'})
          }
          notificationFire(message, constants.general.NOTIFY_ERROR);
        });
      }
    }

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons(){
      return setCurrentPageActionButtons([{"name": constants.general.BACK , 'action': 'back','class': 'btn-primary','isLoader':false, 'iconClass':"bi bi-arrow-left fs-4" }]);
    }
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Product Options", "path": "product-options"}, { name: formatText(route.name), active: true }]);
      getProductOptionDetails();
      generateHeaderToolbarButtons();
    });

    return {
      productOptionDetails,
      loading,
      activePanel,
      constants,
      formatPrice,
      attributeTableHeader,
    };
  },
};
</script>